import axios from 'axios';

export default {
    async getScheme (scheme) {
        return (await axios.get('/api/scheme/' + scheme)).data;
    },
    async watchedIntroVideo () {
        return (await axios.put('/api/scheme/')).data;
    },
    async requestAdvice () {
        return (await axios.post('/api/wpsa')).data;
    },
    async getCanRequestAdvice () {
        return (await axios.get('/api/wpsa')).data;
    }
};