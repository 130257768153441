<template>
   <div class="analytics">
       <div class="sub-header">
           <input type="date" v-model="fromDate"/>
           <input type="date" v-model="toDate"/>
           <button @click="getData">Update data</button>
       </div>
       <div class="tabs">
           <div class="tab" @click="selectedTab = 'charts'" :class="{ active: selectedTab === 'charts' }">Charts</div>
           <div class="tab" @click="selectedTab = 'stats'" :class="{ active: selectedTab === 'stats' }">Stats</div>
           <div class="tab" @click="selectedTab = 'advice'" :class="{ active: selectedTab === 'advice' }">Exploring taking advice</div>
       </div>
       <div class="tab-content">
           <charts ref="charts" v-show="selectedTab === 'charts'" />
           <stats ref="stats" v-show="selectedTab === 'stats'"/>
           <advice ref="wpsa" v-show="selectedTab === 'advice'"/>
       </div>

   </div>
</template>

<script>
import charts from './charts';
import stats from './stats';
import advice from './advice';
import dayjs from 'dayjs';

export default {
    components: {
        charts, stats, advice
    },
    data () {
        return {
            fromDate: '2023-06-01',
            toDate:  '2023-09-01',
            selectedTab: 'charts'
        };
    },
    created () {
        this.toDate = dayjs().format('YYYY-MM-DD');
        this.fromDate = dayjs().subtract(3, 'month').format('YYYY-MM-DD');
    },
    mounted () {
        this.$nextTick(() => {
            this.$refs.charts.getCharts(this.fromDate, dayjs(this.toDate).add(1, 'day').format('YYYY-MM-DD'));
            this.$refs.stats.getStats(this.fromDate, dayjs(this.toDate).add(1, 'day').format('YYYY-MM-DD'));
            this.$refs.wpsa.getStats(this.fromDate, dayjs(this.toDate).add(1, 'day').format('YYYY-MM-DD'));
        });
    },
    methods: {
        getData () {
            this.$refs.charts.getCharts(this.fromDate, dayjs(this.toDate).add(1, 'day').format('YYYY-MM-DD'));
            this.$refs.stats.getStats(this.fromDate, dayjs(this.toDate).add(1, 'day').format('YYYY-MM-DD'));
            this.$refs.wpsa.getStats(this.fromDate, dayjs(this.toDate).add(1, 'day').format('YYYY-MM-DD'));
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../../RetirementPlanner.VueCore/src/scss/variables.scss';
input[type="date"] {
    font-family: sans-serif;
    padding: 0.5rem;
    margin: 0.5rem;
    background: #f4f4f4;
    box-shadow: inset rgba(0, 0, 0, 0.2) 2px 2px 2px;
}

button {
    background: white;
    border: 2px solid $nationalGasBlue;
    border-radius: 2rem;
    color: $nationalGasBlue;
    padding: 0.5rem;
    &:hover, &:focus {
        background-color: $nationalGasBlue;
        color: white;
        cursor: pointer;
        }
}

.tab-content {
    margin-top: 2rem;
}
.sub-header {
    background: $nationalGasTeal;
}
.tabs {
    display: flex;
    width: 100%;
    margin-top: 1rem;
    .tab {
        padding: 1rem;
        cursor: pointer;
        background: $nationalGasTeal;
        color: white;
        margin: 0.5rem 1rem;
        width: 100%;
        position: relative;
        text-align: center;
        &.active {
            background: $nationalGasTeal;
            &:after {
                content: "";
                height: 0;
                width: 0;
                border: 0;
                border-top: 2rem solid $nationalGasTeal;
                border-left: 2rem solid transparent;
                border-right: 2rem solid transparent;
                position: absolute;
                bottom: -1.2rem;
                left: 50%;
                z-index: 1;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
            }
        }
    }
}
</style>
