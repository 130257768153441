import axios from 'axios';

export default {
    async getPageViews (dates) {
        return (await axios.get('/api/analytics/getPageViews/' + dates.start + '/' + dates.end)).data;
    },
    async getPageTimes (dates) {
       return (await axios.get('/api/analytics/getAverageTime/' + dates.start + '/' + dates.end)).data;
    },
    async getVideoViews (dates) {
       return (await axios.get('/api/analytics/getVideoViews/' + dates.start + '/' + dates.end)).data;
    },
    async getClickThroughs (dates) {
       return (await axios.get('/api/analytics/getClickThroughs/' + dates.start + '/' + dates.end)).data;
    },
    async getVideoStats (dates) {
       return (await axios.get('/api/analytics/getVideoStats/' + dates.start + '/' + dates.end)).data;
    },
    async getSessionStats (dates) {
       return (await axios.get('/api/analytics/getSessionStats/' + dates.start + '/' + dates.end)).data;
    },
    async getUserStats (dates) {
       return (await axios.get('/api/analytics/getUserStats/' + dates.start + '/' + dates.end)).data;
    },
    async getWpsaClickthrough (dates) {
       return (await axios.get('/api/analytics/getWpsaSubmissions/' + dates.start + '/' + dates.end)).data;
    },
    async getFeedback () {
       return (await axios.get('/api/analytics/getFeedback/')).data;
    }
};