import dayjs from 'dayjs';
var customParseFormat = require("dayjs/plugin/customParseFormat");

dayjs.extend(customParseFormat);

const DATE_FORMAT = /^\d{2}\/\d{2}\/\d{4}$/;
const DECIMAL_FORMAT = /^-?\d*\.?\d*$/;

let reviver = function (key, value) {
    if (typeof value === "string" && DATE_FORMAT.test(value)) {
        return new dayjs(value, 'DD/MM/YYYY');
    }
    if (typeof value === "string" && DECIMAL_FORMAT.test(value)) {
        return parseFloat(value);
    }
    return value;
};

let parseJson = function (string) {
    return JSON.parse(string,reviver);
};

function roundDownToNearest (value, d) {
    return "" + Math.floor(value / d) * d;
}

function roundDown (value, nearest = 1) {
    const roundedValue = roundDownToNearest(Math.abs(value || 0), nearest);
    let i = String(parseInt(roundedValue));
    let j = i.length > 3 ? i.length % 3 : 0;
    return (j ? i.substring(0, j) + "," : "") + i.substring(j).replace(/(\d{3})(?=\d)/g, "$1" + ",");
}

export default {
    reviver,
    parseJson,
    roundDown,
};
