import axios from 'axios';

export default {
    async getUser (id, scheme) {
        return (await axios.get('/api/users/'+ id + '/' + scheme)).data;
    },
    async getAllUsers () {
        return (await axios.get('/api/users/')).data;
    },
    async getBulk () {
        return (await axios.get('/api/userbulk/')).data;
    },
    async login (username, password) {
        return (await axios.post('/api/submitauth1', { username, password })).data;
    },
    async submitSecurityAnswer (username, answer, id) {
        return (await axios.post('/api/submitauth2', { username, text: answer, id })).data;
    }
};