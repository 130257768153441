import { render, staticRenderFns } from "./captions.vue?vue&type=template&id=097a6882&scoped=true"
import script from "./captions.vue?vue&type=script&lang=js"
export * from "./captions.vue?vue&type=script&lang=js"
import style0 from "./captions.vue?vue&type=style&index=0&id=097a6882&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../Retirement Planner.Admin/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "097a6882",
  null
  
)

export default component.exports