import schemeApi from './../../api/scheme';
const state = {
    schemeDetails: null,
    schemeId: 'nationalGrid'
};

const getters = {
    getSchemeDetails (state) {
        return state.schemeDetails;
    },
    getSchemeId (state) {
        return state.schemeId;
    },
    getIntroVideoWatched () {
        var b = document.cookie.match('(^|;)\\s*' + 'watchedIntroVideo' + '\\s*=\\s*([^;]+)');
        return b ? b.pop() : '';
    }
};

const actions = {
    async getSchemeDetails (context, schemeId) {
        context.commit('setSchemeId', schemeId);
        await schemeApi.getScheme(schemeId).then((schemeDetails) => {
            context.commit('setSchemeDetails', schemeDetails);
            context.commit('setupFactfind', schemeDetails.company);
        });
    },
    setIntroVideoWatched () {
        schemeApi.watchedIntroVideo();
    },
    async requestAdvice () {
        return await schemeApi.requestAdvice();
    },
    async getCanRequestAdvice () {
        return await schemeApi.getCanRequestAdvice();
    }
};

const mutations = {
    setSchemeDetails (state, schemeDetails) {
        state.schemeDetails = schemeDetails;
    },
    setSchemeId (state, schemeId) {
        state.schemeId = schemeId;
    }
};

export default {
    state,
    getters,
    actions,
    mutations
};
