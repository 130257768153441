<template>
    <div class="login">
        <div class="overlay" />
        <img src="/public/logo.png" class="logo" alt="National Gas Transmission Logo"/>
        <div class="login-form">
            <button class="login-button" @click="login" v-if="!loading">Login</button>
            <div class="loading" v-if="loading" />
        </div>
    </div>
</template>

<script>
import auth from '../services/authentication';
export default {
    name: 'login',
    components: {
    },
    data () {
        return {
            loggedIn: false,
            loading: false,
            error: false,
        };
    },
    created () {
        auth.init();

    },
    beforeDestroy () {
    },
    methods: {
        login () {
            auth.signIn();
        }

    },
    computed: {
    },
    mounted () {
    },
};
</script>

<style scoped lang="scss">
@import './../../../RetirementPlanner.VueCore/src/scss/variables.scss';
@import './../../../RetirementPlanner.VueCore/src/scss/mixins.scss';
.error {
    background: $lcpWatermelon;
    margin: 0.5rem;
    padding: 0.25rem;
    a {
        text-align: left;
        color: white;
        text-decoration: underline;
        display: inline-block;
        padding: 0.5rem;
        text-align: left;
    }
}

.forgotton {
    bottom: -160px;
    left: 0;
    width: 100%;
    margin: 0.5rem;
    position: absolute;
    background: #004e86;
    z-index: 1;
    p {
        white-space: nowrap;
        a {
            padding: 0;
            text-decoration: underline;
        }
    }
    a {
        display: block;
        color: white;
        padding: 0.5rem 2rem;
        &:hover {
            text-decoration: underline;
        }
    }
}
.login {
    height: calc(100vh - 2rem);
    position: relative;
    img {
        bottom: 0;
        position: absolute;
        right: 1rem;
        &.cloud {
            height: 100px;
            right: 20rem;
            bottom: 10rem;
            width: auto;
            z-index: 0;
        }
    }
    .logo {
        left: 1rem;
        top: 1rem;
        width: 80vw;
        max-width: 600px;
        height: auto;
    }
    .overlay {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
    .login-form {
        box-shadow: rgba(0, 0, 0, 0.4) 2px 2px 6px;
        color: white;
        left: 50%;
        min-width: 400px;
        overflow: visible;
        padding: 1rem;
        position: absolute;
        text-align: center;
        top: 50%;
        background: $nationalGasTeal;
        transform: translateY(-50%) translateX(-50%);
        z-index: 1;
        @media (max-width: 400px) {
            min-width: 100%;
        }
    }
    .terms {
        background: white;
        left: 50%;
        height: 70vh;
        max-height: 600px;
        max-width: 1000px;
        padding: 1.5rem;
        position: absolute;
        top: 7rem;
        transform: translateX(-50%);
        width: 100%;
        z-index: 9;
    }
    @media (max-width: 1536px) {
        .terms {
            max-height: 450px;
        }
    }
    h2 {
        font-size: 1.2rem;
        color: $nationalGridBlue;
    }
    button.login-button {
        background: white;
        border: 2px solid $nationalGasBlue;
        border-radius: 2rem;
        bottom: 4rem;
        color: $nationalGasBlue;
        left: calc(50% - 4rem);
        margin-top: 1rem;
        padding: 0.5rem 2rem;
        &.disabled {
            background: #aaa;
            pointer-events: none;
        }
        &:hover, &:focus {
            background-color: $nationalGasBlue;
            color: white;
            cursor: pointer;
        }
    }
    .controls {
        text-align: center;
        button {
            background: $nationalGasTeal;
            border: 0;
            bottom: -3rem;
            color: white;
            left: calc(50% - 4rem);
            margin-top: 2rem;
            padding: 0.5rem 2rem;
            position: absolute;
            &.disabled {
                background: #aaa;
                pointer-events: none;
            }
            &:hover, &:focus {
                background: darken($nationalGasTeal, 5);
                cursor: pointer;
            }
        }
    }
}
@media (max-width: 1536px) {

}
</style>
