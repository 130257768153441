<template>
   <div>
       <div class="chart">
           <highcharts v-if="pageViewDataByMonth" :options="pageViewByMonthOptions" />
       </div>
       <div class="chart">
           <highcharts v-if="pageTimeData" :options="pageTimeOptions" />
       </div>
       <div class="chart">
           <highcharts v-if="pageViewData" :options="pageViewOptions" />
       </div>
        <div class="chart">
           <highcharts v-if="videoViewsData && pageViewDataByMonth" :options="videoViewOptions" />
       </div>
       <div class="chart">
           <highcharts v-if="videoViewsData && clickThroughsData" :options="clickThroughsOptions" />
       </div>
   </div>
</template>

<script>
import analyticsApi from './../api/analytics';
import cloneDeep from 'lodash/cloneDeep';
import Highcharts from 'highcharts';
import find from 'lodash/find';
export default {
    data () {
        return {
            pageViews: null,
            pageViewDataByMonth: null,
            pageTimeData: null,
            pageViewData: null,
            videoViewsData: null,
            clickThroughsData: null,
            fromDate: '2019-06-11',
            toDate: '2019-09-01'
        };
    },
    mounted () {
        Highcharts.setOptions({
            colors: ['rgb(0, 133, 122)', 'rgb(0, 76, 157)', 'rgb(0, 178, 161)', 'rgb(124, 196, 0)', '#8ec641', '#fff200']
        });
    },
    methods: {
        async getCharts (fromDate, toDate) {
            this.fromDate = fromDate;
            this.toDate = toDate;
            await analyticsApi.getPageViews({ start: this.fromDate, end: this.toDate }).then((response) => {
                this.pageViews = response;
                this.pageViewDataByMonth = this.getPageViewByMonthData();
                this.pageViewData = this.getPageViewData();
            });

            await analyticsApi.getPageTimes({ start: this.fromDate, end: this.toDate }).then((response) => {
                this.pageTimeData = this.getPageTimeData(response);
            });

            await analyticsApi.getVideoViews({ start: this.fromDate, end: this.toDate }).then((response) => {
                this.videoViewsData = this.getVideoViewsData(response);
            });

            await analyticsApi.getClickThroughs({ start: this.fromDate, end: this.toDate }).then((response) => {
                this.clickThroughsData = this.getClickThroughsData(response);
            });
        },
        getPageViewByMonthData () {
            let allData = {
                43: { name: 'Intro to MRP', data: []},
                45: { name: 'Options', data: []},
                46: { name: 'What do the options mean to me?', data: []},
                47: { name: 'What should I do now?', data: []}
            };
            this.pageViews.forEach(date => {
                Object.keys(allData).forEach((key) => {
                    let dataToPush = 0;
                    const active = find(date.measures.active, a => a.key == key);
                    dataToPush += active ? active.total : 0;
                    const deferred = find (date.measures.deferred, a => a.key == key);
                    dataToPush += deferred ? deferred.total : 0;
                    allData[key].data.push(dataToPush);
                });
            });
            const data = [];
            Object.keys(allData).forEach(d => data.push(allData[d]));
            return data;
        },
        getClickThroughsData (data) {
            let allData = {
                38: { name: 'Click on "Exploring taking financial advice (total)"', data: []},
                41: { name: 'Click on email link for requesting a guaranteed retirement quote (total)', data: []},

            };
            data.forEach(date => {
                Object.keys(allData).forEach((key) => {
                    let dataToPush = 0;
                    const view = find(date.views, a => a.key == key);
                    dataToPush += view ? view.total : 0;
                    allData[key].data.push(dataToPush);
                });
            });
            const finalData = [];
            Object.keys(allData).forEach(d => finalData.push(allData[d]));
            return finalData;
        },
        getVideoViewsData (data) {
            let allData = {
                6: { name: 'Video 1', data: []},
                9: { name: 'Video 2', data: []},
                12: { name: 'Video 3', data: []},
                15: { name: 'Video 4', data: []},
                18: { name: 'Video 5', data: []},
                21: { name: 'Video 6', data: []},
                24: { name: 'Video 7', data: []}
            };
            data.forEach(date => {
                Object.keys(allData).forEach((key) => {
                    let dataToPush = 0;
                    const view = find(date.views, a => a.key == key);
                    dataToPush += view ? view.total : 0;
                    allData[key].data.push(dataToPush);
                });
            });
            const finalData = [];
            Object.keys(allData).forEach(d => finalData.push(allData[d]));
            return finalData;
        },
        getPageTimeData (data) {
            let allData = [
                { name: 'Active', data: []},
                { name: 'Deferred', data: []}
            ];
            const keys = {
                2: [0,0],
                4: [0,0],
                40: [0,0],
                5: [0,0]
            };

            Object.keys(keys).forEach((key) => {
                const active = find(data.active, a => a.key == key);
                const activeTime = active ? active.averageTime : 0;
                const deferred = find (data.deferred, a => a.key == key);
                const deferredTime = deferred ? deferred.averageTime : 0;
                keys[key][0] = activeTime;
                keys[key][1] = deferredTime;
            });
            [2, 4, 40, 5].forEach(d => allData[0].data.push(keys[d][0]));
            [2, 4, 40, 5].forEach(d => allData[1].data.push(keys[d][1]));
            return allData;
        },
        getPageViewData () {
            let allData = [
                { name: 'Active', data: []},
                { name: 'Deferred', data: []}
            ];
            const keys = {
                43: [0,0],
                45: [0,0],
                46: [0,0],
                47: [0,0]
            };

            this.pageViews.forEach(date => {
                Object.keys(keys).forEach((key) => {
                    const active = find(date.measures.active, a => a.key == key);
                    const activeTime = active ? active.total : 0;
                    const deferred = find (date.measures.deferred, a => a.key == key);
                    const deferredTime = deferred ? deferred.total : 0;
                    keys[key][0] += activeTime;
                    keys[key][1] += deferredTime;
                });
            });
            Object.keys(keys).forEach(d => allData[0].data.push(keys[d][0]));
            Object.keys(keys).forEach(d => allData[1].data.push(keys[d][1]));
            return allData;
        }
    },
    computed: {
        pageViewCategories () {
            if (!this.pageViews) return [];
            return this.pageViews.map(a => a.date).sort((a, b) => new Date(a) > new Date(b) ? 1 : -1);
        },
        baseChartOptions () {
            return {
                chart: {
                    type: 'column',
                    width: 1000,
                },
                title: {
                    text: 'MRP page views'
                },
                xAxis: {
                    categories: this.pageViewCategories
                },
                credits: {
                    enabled: false
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: ''
                    },
                    stackLabels: {
                        enabled: true,
                        style: {
                            fontWeight: 'bold',
                        }
                    }
                },
                legend: {
                    align: 'center',
                    x: -30,
                    verticalAlign: 'bottom',
                    y: 25,
                    floating: false,
                    borderColor: '#CCC',
                    borderWidth: 1,
                    shadow: false
                },
                tooltip: {
                    headerFormat: '<b>{point.x}</b><br/>',
                    pointFormat: '{series.name}: {point.y}<br/>Total: {point.stackTotal}'
                },
                plotOptions: {
                    column: {
                        dataLabels: {
                            enabled: false,
                        }
                    }
                },
            };
        },
        pageViewOptions () {
            const options = cloneDeep(this.baseChartOptions);
            options.series = this.pageViewData;
            options.xAxis.categories = [ 'Intro to MRP', 'Options', 'What do the options mean to me?', 'What should I do now?'];
            options.title.text = 'Total page views by member status';
            options.plotOptions.column.stacking = 'normal';
            return options;
        },
        videoViewOptions () {
            const options = cloneDeep(this.baseChartOptions);
            options.series = this.videoViewsData;
            options.title.text = 'Unique video views';
            return options;
        },
        pageViewByMonthOptions () {
            const options = cloneDeep(this.baseChartOptions);
            options.series = this.pageViewDataByMonth;
            return options;
        },
        clickThroughsOptions () {
            const options = cloneDeep(this.baseChartOptions);
            options.series = this.clickThroughsData;
            options.title.text = 'MRP onward clicks';
            return options;
        },
        pageTimeOptions () {
            const options = cloneDeep(this.baseChartOptions);
            options.series = this.pageTimeData;
            options.xAxis.categories = [ 'Intro to MRP', 'Options', 'What do the options mean to me?', 'What should I do now?'];
            options.title.text = 'Av. time (seconds) by page of MRP';
            return options;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../../RetirementPlanner.VueCore/src/scss/variables.scss';
    .chart {
        border: 1px solid #eee;
        padding: 1rem;
        width: 1000px;
        margin: 4rem auto;
        > div {
            overflow: visible !important;
        }
        ::v-deep .highcharts-root {
            overflow: visible;
        }
    }
    input[type="date"] {
        font-family: sans-serif;
        padding: 0.5rem;
        margin: 0.5rem;
        background: #f4f4f4;
        box-shadow: inset rgba(0, 0, 0, 0.2) 2px 2px 2px;
    }

    button {
        background: $nationalGridPink;
        padding: 0.5rem;
        border: 0;
        color: white;
    }
</style>
