import axios from 'axios';

export default {
    postAnalytics (data) {
        axios.post('/api/info', data);
    },
    postAnalyticSync (analyticsData) {
        var client=new XMLHttpRequest;
        client.open("POST","/api/info/", false);
        client.setRequestHeader("content-type","application/json");
        client.send(JSON.stringify(analyticsData));

    }
};
