<template>
   <div>
       <div class="table-container">
           <h3>Feedback</h3>
           <h4>Average rating {{ averageRating }}</h4>
            <table>
                <thead>
                    <th>Date</th>
                    <th>Rating</th>
                    <th>Feedback</th>
                </thead>
                <tbody>
                    <tr v-for="data in feedback" :key="data.timeStamp">
                        <td>{{ formatDate(data.timeStamp) }}</td>
                        <td>{{ parseFeedback(data.value).rating }}</td>
                        <td>{{ parseFeedback(data.value).feedback }}</td>
                    </tr>
                </tbody>
            </table>
       </div>
   </div>
</template>

<script>
import analyticsApi from './../api/analytics';
import dayjs from 'dayjs';

export default {
    data () {
        return {
            feedback: []
        };
    },
    mounted () {
        this.getFeedback();
    },
    methods: {
        formatDate (date) {
            return dayjs(date).format('DD/MM/YYYY HH:mm');
        },
        parseFeedback (feedback) {
            return JSON.parse(feedback);
        },
        async getFeedback () {
            await analyticsApi.getFeedback().then((response) => {
                this.feedback = response.reverse();
            });
        },
    },
    computed: {
        averageRating () {
            let sum = 0;
            this.feedback.forEach((feedback) => {
                sum += JSON.parse(feedback.value).rating;
            });
            return sum / this.feedback.length;
        }
    }
};
</script>

<style lang="scss" scoped>
@import '../../../RetirementPlanner.VueCore/src/scss/variables.scss';
    table {
        width: 100%;
        td, th {
            padding: 0.75rem;
            &:first-child {
                width: 700px;
            }
        }
        thead {
            border-bottom: 1px solid #eee;
            background: #eee;
            color: #444;
        }
        tr {
            border-bottom: 1px solid #eee;
        }
        th {
            text-align: left;
        }
    }

 .table-container {
    padding: 1rem;
    margin: 1rem;
    h3 {
        padding: 1rem 0.25rem;
        color: $nationalGasTeal;
        font-size: 1.4rem;
    }
    h4 {
        padding: 1rem 0.25rem;
    }
 }
</style>
